import { DataForm } from '../../dom/DataForm';
import { I18N, I18NKey } from '../../dom/I18N';
import { ModalController } from '../../dom/ModalController';
import { Templates } from '../../dom/Templates';
import { api, createEndpointDropdown, XhrMethod } from '../../functions';
import { MessageBoard, MessageResponse, MessageType } from '../../MessageBoard';
import { DashboardActor, InstanceAccess } from '../DashboardActor';


/**
 * Makes API-Call to upload war file
 * @param btn
 * @param input
 * @param xcceName
 */
function uploadWarFile(btn: HTMLButtonElement, input: HTMLInputElement, xcceName: string) {
    btn.disabled = true;

    const file = input.files?.item(0);
    if (!file) {
        btn.disabled = false;
        return;
    }
    const formData = new FormData();
    formData.append('war-file', file);
    api(
        XhrMethod.POST,
        '/api/upload/war?xcceName=' + xcceName,
        formData,
        MessageBoard.apiCallback(MessageType.SUCCESS, 'File upload successful'),
        MessageBoard.apiCallback(MessageType.ERROR, MessageResponse),
        () => btn.disabled = false
    );
}

/**
 * Builds the HTML for an accessRow, voucherRow and userAccessRow based on the given InstanceAccess and DashboardActor
 * @param access
 * @param actor
 * @returns 0th: accessRow; 1st: adminOptionsRow, 2nd: voucherRow; 3nd: userAccessRow
 */
export function buildAccessRow(access: InstanceAccess, actor: DashboardActor): [HTMLElement, HTMLElement, HTMLElement, HTMLElement] {
    const role = access.role;
    const xcceName = access.container_name;
    const accessRow = new DataForm(<HTMLTableRowElement>Templates.getClone('access-row'));
    const adminOptionsRow = new DataForm(<HTMLTableRowElement>Templates.getClone('admin-options-row'));
    const voucherRow = new DataForm(<HTMLTableRowElement>Templates.getClone('voucher-table-row'));
    const userAccessRow = new DataForm(<HTMLTableRowElement>Templates.getClone('user-access-row'));

    const addVoucherBtn = voucherRow.getItem<HTMLButtonElement>('add-voucher-btn');
    if (addVoucherBtn) {
        addVoucherBtn.onclick = () => actor.openAddVoucherDialog(xcceName, voucherRow.element);
    }

    // add table cells to accessRow
    // Name cell:
    const showInfos = accessRow.getItem('show-infos');
    if (showInfos) {
        // show addition tables for developers or above
        if (role === 'DEVELOPER' || role === 'ADMIN') {
            showInfos.addEventListener('click', () => {
                if (showInfos.classList.contains('arrow_right')) {   // show vouchers
                    [...actor.root.querySelectorAll('.show-btn.arrow_drop_down')].forEach(btn => {   // reset arrows of other accesses
                        btn.classList.remove('arrow_drop_down');
                        btn.classList.add('arrow_right');
                    });
                    showInfos.classList.remove('arrow_right');
                    showInfos.classList.add('arrow_drop_down');
                    actor.root.querySelectorAll('.admin-options-row').forEach(row => row.classList.add('hidden'));
                    adminOptionsRow.element.classList.remove('hidden');
                    if (role === 'ADMIN') { // show vouchers and users only for admins
                        actor.getVouchers(voucherRow.element, xcceName);
                        actor.getAccesses(userAccessRow.element, xcceName);
                    }
                }
                else {  // hide vouchers
                    showInfos.classList.remove('arrow_drop_down');
                    showInfos.classList.add('arrow_right');
                    adminOptionsRow.element.classList.add('hidden');
                    voucherRow.element.classList.add('hidden');
                    userAccessRow.element.classList.add('hidden');
                }
            });
            showInfos.classList.remove('hidden');
        }
    }

    (<HTMLElement>accessRow.getItem('name-span'))
        .textContent = `${access.name} (${xcceName.replace('xcce_', '')})`;

    (<HTMLElement>accessRow.getItem('edit-span'))
        .addEventListener('click', () => {
            actor.openUpdateAccessDialog(access.name, access.endpoints, xcceName, role);
        });

    // Role cell:
    const roleCell = <HTMLElement>accessRow.getItem('role-cell');
    roleCell.textContent = role;
    if (role === 'ADMIN') {
        roleCell.classList.add('admin-cell');
    }

    // Status cell:
    const statusSpan = <HTMLElement>accessRow.getItem('status-span');
    const reloadIcon = <HTMLElement>accessRow.getItem('reload-icon');
    actor.getStatusForCell(statusSpan, xcceName);
    reloadIcon.addEventListener('click', () => actor.getStatusForCell(statusSpan, xcceName));

    // Instance cell:
    const instanceCellContent = <HTMLElement>accessRow.getItem('instance-cell-content');

    const apiBtn = <HTMLButtonElement>accessRow.getItem('api-btn');
    apiBtn.onclick = () => {
        const createKeyRequest = () => {
            apiBtn.disabled = true;

            api(
                XhrMethod.POST,
                'api/create/token',
                { xcceName },
                (request: XMLHttpRequest) => {
                    const response = JSON.parse(request.response);
                    const token = response.token;
                    access.api_token_preview = response.tokenPreview;

                    ModalController.info(`API-Key:\n${token}`);
                },
                MessageBoard.apiCallback(MessageType.ERROR, MessageResponse),
                () => apiBtn.disabled = false
            );
        };
        if (access.api_token_preview) {
            ModalController.confirm(I18N.translate(I18NKey.CONFIRM_API_KEY) + ' ' + access.api_token_preview,
                createKeyRequest
            );
        } else {
            createKeyRequest();
        }
    };

    const sshBtn = <HTMLButtonElement>accessRow.getItem('ssh-btn');
    if (role === 'ADMIN') {
        sshBtn.onclick = () => {
            sshBtn.disabled = true;

            MessageBoard.show(MessageType.INFO, 'Preparing SSH-Access.');

            api(
                XhrMethod.POST,
                'api/ssh/access',
                { xcceName },
                (request: XMLHttpRequest) => {
                    const commands = JSON.parse(request.response);

                    const linuxCommand = <string>commands?.linuxCommand || '';
                    const windowsCommand = <string>commands?.windowsCommand || '';

                    actor.openSSHCommandDialog(linuxCommand, windowsCommand);
                },
                MessageBoard.apiCallback(MessageType.ERROR, MessageResponse),
                () => sshBtn.disabled = false
            );
        };
    } else {
        sshBtn.classList.add('hidden');
    }
    const dropdown = <HTMLSelectElement>accessRow.getItem('dropdown');
    createEndpointDropdown(dropdown, access.endpoints);

    (<HTMLButtonElement>accessRow.getItem('open-btn')).onclick = () => {
        actor.openFactory(xcceName, dropdown);
    };

    if (actor.isOwner()) {
        const deleteBtn = <HTMLButtonElement>Templates.getClone('delete-btn');
        deleteBtn.onclick = () => ModalController.confirm(
            `Do you really want to delete this instance (${xcceName})?`,
            () => {
                deleteBtn.disabled = true;
                MessageBoard.show(MessageType.INFO, 'Deleting instance. This may take a few seconds.');
                actor.deleteInstance(xcceName);
            });
        instanceCellContent.appendChild(deleteBtn);
    } else {
        const removeBtn = <HTMLButtonElement>Templates.getClone('remove-btn');
        removeBtn.onclick = () => {
            removeBtn.disabled = true;
            actor.removeAccess(xcceName);
        };
        instanceCellContent.appendChild(removeBtn);
    }

    const warInput = adminOptionsRow.getItem<HTMLInputElement>('war-file-input');
    const warUploadBtn = adminOptionsRow.getItem<HTMLButtonElement>('war-upload-btn');
    if (warInput && warUploadBtn) {
        warUploadBtn.onclick = () => uploadWarFile(warUploadBtn, warInput, xcceName);
    }

    return [accessRow.element, adminOptionsRow.element, voucherRow.element, userAccessRow.element];
}
