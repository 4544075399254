export enum MessageType {
    INFO,
    ERROR,
    SUCCESS,
    SYSTEM
}


export const MessageResponse = Symbol();


class MessageBoardSingelton {

    private readonly banners = new Map<MessageType, HTMLElement>();
    private visibleMessages = 0;


    constructor() {
        this.banners.set(MessageType.INFO, document.querySelector('.info-banner:not(.sticky)') as HTMLElement);
        this.banners.set(MessageType.ERROR, document.querySelector('.error-banner:not(.sticky)') as HTMLElement);
        this.banners.set(MessageType.SUCCESS, document.querySelector('.success-banner:not(.sticky)') as HTMLElement);
        this.banners.set(MessageType.SYSTEM, document.querySelector('.system-banner:not(.sticky)') as HTMLElement);

        // add closeBtn functionality to banners
        this.banners.forEach(banner => {
            this.setBannerVisibility(banner, false);

            const closeButton = banner.querySelector('.close-btn');
            closeButton?.addEventListener('click', () => {
                this.setBannerVisibility(banner, false);
                this.visibleMessages--;
            });
        });
    }


    /**
     * Adds classes to the given banner to make it visible or not
     * @param banner Message banner to show or hide
     * @param visible Determines whether the banner should be visible
     */
    private setBannerVisibility(banner: HTMLElement, visible: boolean) {
        if (visible) {
            banner.classList.add('visible-banner');
            banner.classList.remove('hidden-banner');
        } else {
            banner.classList.add('hidden-banner');
            banner.classList.remove('visible-banner');
        }
    }


    /**
     * Hides all messages
     */
    hide() {
        this.banners.forEach(banner => this.setBannerVisibility(banner, false));
    }


    /**
     * Displays a message of the given type
     * @param type Type of message
     * @param message Message to be displayed
     * @param timeout Number of milliseconds before the message will disappear again
     */
    show(type: MessageType, message?: string, timeout = 2000) {
        const banner = this.banners.get(type);
        if (banner) {
            this.hide();
            this.setBannerVisibility(banner, true);
            const span = banner.querySelector('.msg');
            if (span) {
                span.textContent = message ?? '';
            }

            this.visibleMessages++;
            setTimeout(() => {
                if (--this.visibleMessages === 0) {
                    this.hide();
                }
            }, timeout);
        }
    }


    apiCallback(type: MessageType, message: string | typeof MessageResponse, then?: (request: XMLHttpRequest) => any): (request: XMLHttpRequest) => void {
        return request => {
            this.show(type, message === MessageResponse ? JSON.parse(request.response).message : message);
            then?.(request);
        };
    }
}


export const MessageBoard = new MessageBoardSingelton();
