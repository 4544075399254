import { conditionalClass, isString, pack } from '../functions';
import { DataForm } from './DataForm';
import { I18N, I18NKey } from './I18N';
import { Templates } from './Templates';


export type ModalCallback = (dialog: DataForm) => any;


export interface ModalAction {
    name: string;
    focus: boolean;
    callback?: ModalCallback;
}


class ModalControllerSingleton {

    private readonly stack: DataForm[];
    private readonly stage: HTMLElement;
    private static readonly FOCUS_ATTRIBUTE = 'focus';


    constructor() {
        this.stack = [];
        this.stage = window.document.getElementById('modal') ?? window.document.body;
        window.document.addEventListener('focus', (event: FocusEvent) => {
            const top = this.getTop();
            if (top && !top.element.contains(event.target as Node)) {
                event.target?.dispatchEvent(new FocusEvent('blur'));
                this.refocus(top);
            }
        }, true);
    }


    private getTop(): DataForm | null {
        const stackSize = this.stack.length;
        return stackSize > 0
            ? this.stack[stackSize - 1]
            : null;
    }


    private refocus(modal: DataForm) {
        const buttons = modal.getItem('buttons');
        if (buttons) {
            const focusButton = buttons.querySelector('[data-' + ModalControllerSingleton.FOCUS_ATTRIBUTE + ']') as HTMLButtonElement;
            if (focusButton) {
                focusButton.focus();
            } else {
                buttons.querySelector('button')?.focus();
            }
        }
    }


    private update() {
        // update stage visibility
        conditionalClass(this.stage, 'hidden', this.stack.length === 0);
        // remove all dialogs from stage
        while (this.stage.lastChild) {
            this.stage.removeChild(this.stage.lastChild);
        }
        // add top most dialog to stage
        const top = this.getTop();
        if (top) {
            this.stage.appendChild(top.element);
            this.refocus(top);
        }
    }


    /**
     * Shows a modal dialog with a message or an element
     * @param  title   Dialog title
     * @param  content Message or element
     * @param  actions Array of actions or single action
     * @return Shown dialog
     */
    dialog(title?: string, content?: string | HTMLElement, actions?: ModalAction | ModalAction[]): DataForm | undefined {
        const template = Templates.getClone('dialog');
        if (template) {
            title ??= '';
            content ??= '';
            const dialog = new DataForm(template);
            const titleItem = dialog.getItem('title');
            if (titleItem) {
                titleItem.textContent = title;
            }
            const contentItem = dialog.getItem('content');
            if (contentItem) {
                if (isString(content)) {
                    contentItem.textContent = content;
                } else {
                    dialog.addItems(content);
                    contentItem.appendChild(content);
                }
            }
            const buttons = dialog.getItem('buttons');
            if (buttons) {
                pack(actions).forEach(action => {
                    const button = Templates.getClone('dialog-button');
                    if (button) {
                        button.textContent = action.name;
                        button.onclick = () => action.callback?.(dialog);
                        if (action.focus) {
                            button.dataset[ModalControllerSingleton.FOCUS_ATTRIBUTE] = '';
                        }
                        buttons.appendChild(button);
                    }
                });
            }
            this.stack.push(dialog);
            this.update();
            return dialog;
        }
    }

    /**
     * Shows a info dialog with a message or an element
     * @param  content Message or element
     * @param  callback Callback for confirm action
     * @return Shown dialog
     */
    info(content?: string | HTMLElement): DataForm | undefined {
        return this.dialog(
            I18N.translate(I18NKey.DIALOG_TITLE_INFO),
            content,
            [
                this.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_OK), undefined, true)
            ]
        );
    }

    /**
     * Shows a confirm dialog with a message or an element
     * @param  content Message or element
     * @param  callback Callback for confirm action
     * @return Shown dialog
     */
    confirm(content?: string | HTMLElement, callback?: ModalCallback): DataForm | undefined {
        return this.dialog(
            I18N.translate(I18NKey.DIALOG_TITLE_CONFIRM),
            content,
            [
                this.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_NO)),
                this.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_YES), callback, true)
            ]
        );
    }


    /**
     * Hides the given modal
     * @param modal Modal to hide
     */
    hide(modal: DataForm) {
        const idx = this.stack.indexOf(modal);
        if (idx >= 0) {
            this.stack.splice(idx, 1);
            this.update();
        }
    }


    defaultAction(name?: string, callback?: ModalCallback, focus = false): ModalAction {
        return {name: name ?? '', callback, focus};
    }


    closeAction(name?: string, callback?: ModalCallback, focus = false): ModalAction {
        return this.defaultAction(name, dialog => {
            callback?.(dialog);
            this.hide(dialog);
        }, focus);
    }
}


export const ModalController = new ModalControllerSingleton();
