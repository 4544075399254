import { StateController } from '../dom/StateController';
import { api, XhrMethod } from '../functions';
import { MessageBoard, MessageResponse, MessageType } from '../MessageBoard';
import { BaseActor } from './BaseActor';


export class LoginActor extends BaseActor {
    constructor(root: HTMLElement) {
        super(root);

        root.querySelector('button')?.addEventListener('click', () => {
            // TODO: duplicated code
            let btn = root.querySelector('button');
            if (btn) {
                btn.disabled = true;
            }
            const username = root.querySelector<HTMLInputElement>('input[name=username]')?.value;
            const password = root.querySelector<HTMLInputElement>('input[name=password]')?.value;

            api(
                XhrMethod.POST,
                '/api/login',
                {username, password},
                () => StateController.transition('dashboard'),
                MessageBoard.apiCallback(MessageType.ERROR, MessageResponse),
                () => {
                    // TODO: duplicated code
                    btn = root.querySelector('button');
                    if (btn) {
                        btn.disabled = false;
                    }
                });
        });
    }

    show() {
        console.log('Show login');
        api(
            XhrMethod.GET,
            'api/probe',
            undefined,
            () => StateController.transition('dashboard')
        );
    }

    hide() {
        super.hide();
    }
}
