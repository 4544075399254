import { DataForm } from '../dom/DataForm';
import { I18N, I18NKey } from '../dom/I18N';
import { ModalController } from '../dom/ModalController';
import { StateController } from '../dom/StateController';
import { Templates } from '../dom/Templates';
import { api, createButton, createButtonCell, createElement, XhrMethod } from '../functions';
import { MessageBoard, MessageResponse, MessageType } from '../MessageBoard';
import { BaseActor } from './BaseActor';
import { buildAccessRow } from './DashboardActor/AccessRow';


/**
 * Interface to represent the access to an instance (used for access table)
 */
export interface InstanceAccess {
    user_id: number;
    container_name: string;
    role: string;
    name: string;
    endpoints: string;
    api_token_preview: string;
}

export interface User {
    email: string;
    username: string;
    last_login: Date;
    first_login: Date;
    login_tries: number;
    locked: boolean;
    owner: boolean;
}

interface Instance {
    container_name: string;
    endpoints: string;
}

interface Voucher {
    id: number;
    value: string;
    avail: number;
    instance: Instance;
    container_name: string;
    role: string;
    name?: string;
}


/**
 * Interface to represent data received from a dashboard API-call
 */
interface DashboardData {
    accesses: InstanceAccess[];
    users?: User[];
    username: string;
    owner?: boolean;
}


export class DashboardActor extends BaseActor {
    private accesses = <InstanceAccess[]>[];
    private users = <User[]>[];
    private username = '';
    private owner = false;
    private readonly compFns = {
        NAME: (a: InstanceAccess, b: InstanceAccess) => a.name.localeCompare(b.name),
        USERNAME: (a: User, b: User) => a.username.localeCompare(b.username)
        // TODO: add more
    };

    constructor(root: HTMLElement) {
        super(root);

        // has to be document, because logout-btn is in navbar
        const logoutBtn = document.querySelector<HTMLButtonElement>('.logout-btn');
        if (logoutBtn) {
            logoutBtn.addEventListener('click', () =>
                api(
                    XhrMethod.POST,
                    '/api/logout',
                    undefined,
                    () => window.location.replace('/index.html?msg=SUCCESS Successfully logged out')
                )
            );
        }

        const deleteBtn = this.root.querySelector<HTMLButtonElement>('.delete-btn');
        if (deleteBtn) {
            deleteBtn.addEventListener('click', () => ModalController.confirm(
                'Do you really want to delete your account?',
                () => {
                    deleteBtn.disabled = true;
                    api(
                        XhrMethod.POST,
                        '/api/delete/user',
                        { username: this.username },
                        MessageBoard.apiCallback(MessageType.SUCCESS, 'Account deleted.', () => StateController.transition('login')),
                        MessageBoard.apiCallback(MessageType.ERROR, MessageResponse),
                        () => deleteBtn.disabled = false
                    );
                }
            ));
        }

        const addAccessBtn = this.root.querySelector<HTMLButtonElement>('.add-access');
        if (addAccessBtn) {
            addAccessBtn.onclick = () => this.openAddAccessDialog();
        }
    }

    isOwner(): boolean {
        return this.owner;
    }

    openAddAccessDialog() {
        const handleAdd = (dialog: DataForm) => {
            const voucherValue = dialog.getItem<HTMLInputElement>('voucher-value')?.value;

            if (voucherValue) {
                api(
                    XhrMethod.POST,
                    '/api/add/access',
                    { voucher: voucherValue },
                    MessageBoard.apiCallback(MessageType.SUCCESS, 'Access added.', () => this.refresh()),
                    MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
                );
            } else {
                MessageBoard.show(MessageType.ERROR, 'No voucher provided!');
            }
        };

        ModalController.dialog(
            I18N.translate(I18NKey.ADD_ACCESS),
            Templates.getClone('dialog-add-access'),
            [
                ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_CANCEL)),
                ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_ADD), handleAdd, true)
            ]
        );
    }


    openUpdateAccessDialog(currentName: string, currentEndpoints: string, xcceName: string, role: string) {
        const handleRename = (dialog: DataForm) => {
            const accessName = dialog.getItem<HTMLInputElement>('access-name')?.value;
            const accessEndpoints = dialog.getItem<HTMLInputElement>('access-endpoints')?.value;

            if (accessName) {
                api(
                    XhrMethod.POST,
                    '/api/update/access',
                    { instance: role + '/' + xcceName, name: accessName, endpoints: accessEndpoints },
                    MessageBoard.apiCallback(MessageType.SUCCESS, 'Access updated.', () => this.refresh()),
                    MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
                );
            } else {
                MessageBoard.show(MessageType.ERROR, 'No voucher provided!');
            }
        };

        const dialog = ModalController.dialog(
            I18N.translate(I18NKey.UPDATE_ACCESS),
            Templates.getClone('dialog-update-access'),
            [
                ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_CANCEL)),
                ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_OK), handleRename, true)
            ]
        );
        const accessNameInput = dialog?.getItem<HTMLInputElement>('access-name');
        if (accessNameInput) {
            accessNameInput.value = currentName;
        }
        const accessEndpointsInput = dialog?.getItem<HTMLInputElement>('access-endpoints');
        if (accessEndpointsInput) {
            accessEndpointsInput.value = currentEndpoints;
        }
    }


    /**
     * Makes an API-call to delete a voucher with the given id. Then resets the voucher table provided
     * @param id ID of the voucher that shall be removed.
     * @param voucherRow VoucherRow that needs to be updated after deletion.
     * @param xcceName xcceName of corresponding instance (needed to update voucherRow).
     */
    deleteVoucher(id: number, voucherRow: HTMLElement, xcceName: string) {
        api(
            XhrMethod.POST,
            'api/delete/voucher',
            { id },
            MessageBoard.apiCallback(MessageType.SUCCESS, 'Deleted voucher successfully'),
            MessageBoard.apiCallback(MessageType.ERROR, MessageResponse),
            () => this.getVouchers(voucherRow, xcceName)
        );
    }


    /**
     * Opens the dialog window to create a new voucher
     * @param voucher Voucher to be updated
     * @param voucherRow HTML row that will be affected by the new voucher
     * @param xcceName
     */
    openUpdateVoucherDialog(voucher: Voucher, voucherRow: HTMLElement, xcceName: string) {
        const handleUpdate = (dialog: DataForm) => {
            const voucherValue = dialog.getItem<HTMLInputElement>('value')?.value;
            const avail = dialog.getItem<HTMLInputElement>('avail')?.value;
            const defaultName = dialog.getItem<HTMLInputElement>('defaultName')?.value;

            if (!voucherValue || !avail || !defaultName) {
                MessageBoard.show(MessageType.ERROR, 'Not all parameters are set!');
                return;
            }

            api(
                XhrMethod.POST,
                'api/update/voucher',
                { id: voucher.id, value: voucherValue, avail, name: defaultName },
                MessageBoard.apiCallback(MessageType.SUCCESS, 'Voucher updated.', () => this.getVouchers(voucherRow, xcceName)),
                MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
            );
        };

        const dialog = ModalController.dialog(
            I18N.translate(I18NKey.UPDATE_VOUCHER) + ` ${voucher.value}`,
            Templates.getClone('dialog-update-voucher'),
            [
                ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_CANCEL)),
                ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_OK), handleUpdate, true)
            ]
        );

        const valueInput = dialog?.getItem<HTMLInputElement>('value');
        if (valueInput) { valueInput.value = voucher.value; }
        const roleLabel = dialog?.getItem('role-field');
        if (roleLabel) { roleLabel.classList.add('hidden'); }
        const availInput = dialog?.getItem<HTMLInputElement>('avail');
        if (availInput) { availInput.value = '' + voucher.avail; }
        const defaultNameInput = dialog?.getItem<HTMLInputElement>('defaultName');
        if (defaultNameInput) { defaultNameInput.value = voucher.name || ''; }
    }


    /**
     * Generates HTML for the voucher table and puts it inside the given voucherRow
     * @param voucherRow
     * @param vouchers
     */
    setUpVoucherTable(voucherRow: HTMLElement, vouchers: Voucher[]) {
        // Hide all voucherTables
        [...document.getElementsByClassName('voucher-row')].forEach(row => row.classList.add('hidden'));

        // remove hidden for provided table
        voucherRow.classList.remove('hidden');

        const voucherTable = voucherRow.querySelector('.voucher-table');
        if (!voucherTable) { return; }
        const headerRow = voucherTable.querySelector('.header-row');
        const addVoucherRow = voucherTable.querySelector('.add-voucher-row');
        voucherTable.innerHTML = '';
        if (headerRow) {
            voucherTable.appendChild(headerRow);
        }

        vouchers.forEach(voucher => {
            const role = voucher.role;
            const xcceName = voucher.container_name;

            const row = document.createElement('tr');

            {
                // wrap value inside <pre> to show all whitespaces
                const valueFiled = createElement('pre', voucher.value);
                const tableField = document.createElement('td');
                tableField.appendChild(valueFiled);
                row.appendChild(tableField);
            }
            row.appendChild(createElement('td', role));
            row.appendChild(createElement('td', '' + voucher.avail));
            row.appendChild(createElement('td', voucher.name || '-'));

            const actionsCell = document.createElement('td');
            row.appendChild(actionsCell);
            actionsCell.classList.add('multi-button-field', 'actions-cell');
            const actionsCellContent = document.createElement('div');
            actionsCell.appendChild(actionsCellContent);
            {
                const edit = document.createElement('button');
                const icon = document.createElement('i');
                icon.classList.add('icon', 'edit');
                const label = createElement('span', 'Edit');
                edit.appendChild(icon);
                edit.appendChild(label);
                edit.title = I18N.translate(I18NKey.EDIT_VOUCHER) as string;
                edit.classList.add('edit-btn', 'text-icon-btn');
                edit.onclick = () => this.openUpdateVoucherDialog(voucher, voucherRow, xcceName);
                actionsCellContent.appendChild(edit);
            }
            {
                const deleteBtn = document.createElement('button');
                const icon = document.createElement('i');
                icon.classList.add('icon', 'delete');
                const label = createElement('span', 'Delete');
                deleteBtn.appendChild(icon);
                deleteBtn.appendChild(label);
                deleteBtn.title = I18N.translate(I18NKey.DELETE_VOUCHER) as string;
                deleteBtn.classList.add('delete-btn', 'text-icon-btn');
                deleteBtn.onclick = () => this.deleteVoucher(voucher.id, voucherRow, xcceName);
                actionsCellContent.appendChild(deleteBtn);
            }
            voucherTable.appendChild(row);
        });

        if (addVoucherRow) {
            voucherTable.appendChild(addVoucherRow);
        }
    }


    /**
     * Generates HTML for the user-access table and puts it inside the given voucherRow
     * @param userAccessRow
     * @param accesses
     */
    setUpUserAccessTable(userAccessRow: HTMLElement, xcceName: string, accesses: { username: string; role: string }[]) {
        // Hide all voucherTables
        [...document.getElementsByClassName('user-access-row')].forEach(row => row.classList.add('hidden'));

        // remove hidden for provided table
        userAccessRow.classList.remove('hidden');

        const userAccessTable = userAccessRow.querySelector('.user-access-table');
        if (!userAccessTable) { return; }
        const headerRow = userAccessTable.querySelector('.header-row');
        userAccessTable.innerHTML = '';
        if (headerRow) {
            userAccessTable.appendChild(headerRow);
        }

        accesses.forEach(access => {

            const row = document.createElement('tr');

            row.appendChild(createElement('td', access.username));
            row.appendChild(createElement('td', access.role));

            row.appendChild(createButtonCell('delete', () => this.revokeAccess(userAccessRow, access.username, xcceName), true, I18N.translate(I18NKey.REMOVE_ACCESS)));

            userAccessTable.appendChild(row);
        });
    }


    /**
     * Makes an API call to get all the vouchers for a given xcceName and updates the voucher table
     * @param voucherRow
     * @param xcceName
     */
    getVouchers(voucherRow: HTMLElement, xcceName: string) {
        api(
            XhrMethod.GET,
            'api/vouchers?xcceName=' + xcceName,
            undefined,
            (request: XMLHttpRequest) => {
                const response = JSON.parse(request.response);
                this.setUpVoucherTable(voucherRow, response.vouchers);
            },
            MessageBoard.apiCallback(MessageType.ERROR, 'Cannot show dashboard. Login first.', () => StateController.transition('login'))
        );
    }


    /**
     * Makes an API call to get all the accesses for a given xcceName
     * @param userAccessRow
     * @param xcceName
     */
    getAccesses(userAccessRow: HTMLElement, xcceName: string) {
        api(
            XhrMethod.GET,
            'api/accesses?xcceName=' + xcceName,
            undefined,
            (request: XMLHttpRequest) => {
                const response = JSON.parse(request.response);
                this.setUpUserAccessTable(userAccessRow, xcceName, response.accesses);
            },
            MessageBoard.apiCallback(MessageType.ERROR, 'Cannot show dashboard. Login first.', () => StateController.transition('login'))
        );
    }


    /**
     * Opens the dialog window to create a new voucher
     * @param xcceName xcceName to add voucher for
     * @param voucherRow HTML row that will be affected by the new voucher
     */
    openAddVoucherDialog(xcceName: string, voucherRow: HTMLElement) {
        const handleAdding = (dialog: DataForm) => {
            const value = dialog.getItem<HTMLInputElement>('value')?.value;
            const role = dialog.getItem<HTMLInputElement>('role')?.value;
            const avail = dialog.getItem<HTMLInputElement>('avail')?.value;
            const defaultName = dialog.getItem<HTMLInputElement>('defaultName')?.value;
            if (!value || !role || !avail) {
                MessageBoard.show(MessageType.ERROR, 'Not all parameters are set!');
                return;
            }

            api(
                XhrMethod.POST,
                'api/create/voucher',
                { xcceName, value, avail, role, defaultName },
                MessageBoard.apiCallback(MessageType.SUCCESS, 'New voucher created.', () => this.getVouchers(voucherRow, xcceName)),
                MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
            );
        };

        const dialog = ModalController.dialog(
            I18N.translate(I18NKey.ADD_VOUCHER) + ` ${xcceName}`,
            Templates.getClone('dialog-update-voucher'),
            [
                ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_CANCEL)),
                ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_OK), handleAdding, true)
            ]
        );

        const valueInput = dialog?.getItem<HTMLInputElement>('value');
        if (valueInput) { valueInput.value = ''; }
        const roleInput = dialog?.getItem<HTMLInputElement>('role');
        if (roleInput) { roleInput.value = ''; }
        const availInput = dialog?.getItem<HTMLInputElement>('avail');
        if (availInput) { availInput.value = ''; }
        const defaultNameInput = dialog?.getItem<HTMLInputElement>('defaultName');
        if (defaultNameInput) { defaultNameInput.value = ''; }
    }

    openSSHCommandDialog(linuxCommand: string, windowsCommand: string) {
        const copyString = (text: string) => {
            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                return navigator.clipboard.writeText(text).then(() => {
                    MessageBoard.show(MessageType.INFO, 'Copied command to clipboard');
                });
            }
        };

        const dialog = ModalController.dialog(
            I18N.translate(I18NKey.SSH_COMMANDS),
            Templates.getClone('dialog-ssh-commands'),
            [
                ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_CANCEL)),
                ModalController.closeAction(
                    I18N.translate(I18NKey.DIALOG_ACTION_COPY) + ' ' + I18N.translate(I18NKey.LINUX_COMMAND),
                    () => copyString(linuxCommand),
                    true
                ),
                ModalController.closeAction(
                    I18N.translate(I18NKey.DIALOG_ACTION_COPY) + ' ' + I18N.translate(I18NKey.WINDOWS_COMMAND),
                    () => copyString(windowsCommand)
                )
            ]
        );

        const linuxCmd = dialog?.getItem('linux-command');
        if (linuxCmd) { linuxCmd.textContent = linuxCommand; }
        const windowsCmd = dialog?.getItem('windows-command');
        if (windowsCmd) { windowsCmd.textContent = windowsCommand; }
    }

    /**
     * Opens factory of the given name in a new tab. Opens the endpoint that is currently selected in the dropdown menu
     * @param xcceName
     * @param dropdown
     */
    openFactory(xcceName: string, dropdown: HTMLSelectElement) {
        const endpoint = '/' + dropdown.value + '/';
        api(
            XhrMethod.GET,
            '/api/xyna?xcceName=' + encodeURIComponent(xcceName),
            undefined,
            () => window.open(endpoint, 'XynaModeller')?.focus(),
            MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
        );
    }


    /**
     * Make AIP-call to remove access to the current instance for the given user
     * @param xcceName
     */
    removeAccess(xcceName: string) {
        api(
            XhrMethod.POST,
            'api/delete/access',
            { xcceName },
            MessageBoard.apiCallback(MessageType.SUCCESS, 'Access removed.', () => this.refresh()),
            MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
        );
    }


    /**
     * Removes access for the given user to the provided factory
     * @param username
     * @param xcceName
     */
    revokeAccess(userAccessRow: HTMLElement, username: string, xcceName: string) {
        api(
            XhrMethod.POST,
            '/api/revoke/access',
            { username, xcceName: xcceName },
            MessageBoard.apiCallback(MessageType.SUCCESS, MessageResponse, () => this.getAccesses(userAccessRow, xcceName)),
            MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
        );
    }


    /**
     * Delete instance with provided name
     * @param xcceName
     */
    deleteInstance(xcceName: string) {
        api(
            XhrMethod.POST,
            '/api/delete/instance',
            { xcceName, confirmed: true },
            MessageBoard.apiCallback(MessageType.SUCCESS, 'Instance deleted.', () => this.refresh()),
            MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
        );
    }

    /**
     * Locks the given user
     * TODO add confirmation dialog
     * @param username
     */
    lockUser(username: string) {
        api(
            XhrMethod.POST,
            '/api/lock/user',
            { username },
            MessageBoard.apiCallback(MessageType.SUCCESS, 'User locked.', () => this.refresh()),
            MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
        );
    }

    /**
     * Unlocks the given user
     * TODO add confirmation dialog
     * @param username
     */
    unlockUser(username: string) {
        api(
            XhrMethod.POST,
            '/api/unlock/user',
            { username },
            MessageBoard.apiCallback(MessageType.SUCCESS, 'User unlocked.', () => this.refresh()),
            MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
        );
    }


    /**
     * Deletes the given user
     * TODO add confirmation dialog
     * @param username
     */
    deleteUser(username: string) {
        api(
            XhrMethod.POST,
            '/api/delete/user',
            { username },
            MessageBoard.apiCallback(MessageType.SUCCESS, 'User deleted.', () => this.refresh()),
            MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
        );
    }


    getStatusForCell(statusCell: HTMLElement, xcceName: string) {
        // loading spinner
        const sequence = '⠋⠙⠹⠸⠼⠴⠦⠧⠇⠏';
        const printSpinner = (step: number) => {
            statusCell.innerText = sequence[step];
        };
        let currStep = 0;
        const handler = setInterval(() => {
            printSpinner(currStep);
            currStep = (currStep + 1) % sequence.length;
        }, 100);


        const statusToColor = new Map([
            ['Not running', 'blue'],
            ['Starting', 'blue'],
            ['Up and running', '']
        ]);

        api(
            XhrMethod.GET,
            '/api/status?xcceName=' + encodeURIComponent(xcceName),
            undefined,
            (request: XMLHttpRequest) => {
                statusCell.innerText = '';

                const status = (JSON.parse(request.response).status ?? '-').split(';');

                const statusSpan = createElement('span', status[0]);
                statusCell.appendChild(statusSpan);
                const colorClass = statusToColor.get(status[0].trim());
                if (colorClass) {
                    statusSpan.classList.add(colorClass);
                }

                if (status.length > 1 && status[0].trim() === 'Up and running') {
                    const infoSpan = createElement('span', ';' + status[1]);
                    statusCell.appendChild(infoSpan);
                    infoSpan.classList.add('credentials-span');

                    // extract password from status message
                    const startIndex = (<string>status[1]).indexOf(',') + 2; // since we also need to skip a space after the ","
                    const endIndex = (<string>status[1]).indexOf('>');
                    const initialPW = (<string>status[1]).substring(startIndex, endIndex);

                    // copy pw to clipboard
                    infoSpan.onclick = () => {
                        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                            return navigator.clipboard.writeText(initialPW).then(() => {
                                MessageBoard.show(MessageType.INFO, 'Copied initial password to clipboard');
                            });
                        }
                    };
                }
            },
            () => statusCell.textContent = '-',
            () => clearInterval(handler)
        );
    }


    /**
     * Sets up the HTML for the instance access table. Each Factory the user has access to gets its own row.
     * Additionally for each row there will be a voucher-row added to show vouchers and a accesses-row to show the users that have access to that factory if needed
     * @param sortBy Value to sort accesses by
     */
    setUpAccessTable(sortBy = this.compFns.NAME) {
        const sorted = this.accesses.sort(sortBy);
        const accessTable = this.root.querySelector('.instances');
        if (!accessTable) { return; }
        const accessHeaderRow = accessTable.querySelector('.header-row');
        const addAccessRow = accessTable.querySelector('.add-access-row');
        accessTable.innerHTML = '';
        if (accessHeaderRow) {
            accessTable.appendChild(accessHeaderRow);
        }

        sorted.forEach(access => {
            const [accessRow, adminOptionsRow, voucherRow, userAccessRow] = buildAccessRow(access, this);

            // add rows to table
            accessTable.appendChild(accessRow);
            accessTable.appendChild(adminOptionsRow);
            accessTable.appendChild(voucherRow);
            accessTable.appendChild(userAccessRow);
        });

        if (addAccessRow) {
            accessTable.appendChild(addAccessRow);
        }
    }


    /**
     * Builds the HTML for a user-row based on the given User
     * @param user The user to build the row for
     * @returns The HTMLElement of the user-row
     */
    buildUserRow(user: User): HTMLElement {
        const userRow = new DataForm(<HTMLTableRowElement>Templates.getClone('user-row'));

        const nameCell = <HTMLElement>userRow.getItem('name-cell');
        nameCell.textContent = user.username;

        const roleCell = <HTMLElement>userRow.getItem('role-cell');
        roleCell.textContent = user.owner ? 'Owner' : 'User';

        const emailCell = <HTMLElement>userRow.getItem('email-cell');
        emailCell.textContent = user.email;

        const actionsCellContent = <HTMLElement>userRow.getItem('actions-cell-content');

        if (user.locked) {
            const unlockUserButton = createButton('lock_open', () => this.unlockUser(user.username), true, I18N.translate(I18NKey.UNLOCK_USER));
            actionsCellContent.appendChild(unlockUserButton);
            userRow.element.classList.add('locked');
        } else {
            const lockUserButton = createButton('lock', () => this.lockUser(user.username), true, I18N.translate(I18NKey.LOCK_USER));
            actionsCellContent.appendChild(lockUserButton);
        }

        const deleteUserButton = <HTMLButtonElement>userRow.getItem('delete-btn');
        deleteUserButton.onclick = () => ModalController.confirm(
            `Do you really want to delete this user (${user.username})?`,
            () => this.deleteUser(user.username)
        );

        return userRow.element;
    }


    /**
     * Sets up the HTML for the user table. Each user gets its own row.
     * @param sortBy Compare functions to sort users, i.e. the rows, by
     */
    setUpUserTable(sortBy = this.compFns.USERNAME) {
        const sorted = this.users.sort(sortBy);
        const userTable = this.root.querySelector('.users');
        if (!userTable) { return; }
        const userHeaderRow = userTable.querySelector('.header-row');
        userTable.innerHTML = '';

        if (this.owner) {
            if (userHeaderRow) {
                userTable.appendChild(userHeaderRow);
            }

            sorted.forEach(user => {
                const userRow = this.buildUserRow(user);
                userTable.appendChild(userRow);
            });
        }
    }


    showOwnerStyle() {
        if (!this.owner) {
            return;
        }

        const dashboardHeader = document.querySelector<HTMLElement>('.dashboard .header h1');
        const nameField = dashboardHeader?.querySelector<HTMLElement>('.username');
        if (dashboardHeader) {
            dashboardHeader.style.color = '#fabb00';
        }
        if (nameField) {
            nameField.textContent += ' (Owner)';
        }
    }


    /**
     * Sets up dashboard with the logged in users data
     * @param data Data received from a dashboard GET-request
     */
    setFields(data: DashboardData) {
        this.username = data.username;
        this.accesses = data.accesses;
        this.users = data.users ?? [];
        this.owner = data.owner ?? false;

        [...this.root.getElementsByClassName('username')].forEach(element => {
            element.textContent = data.username;
        });

        const instanceBtn = this.root.querySelector<HTMLButtonElement>('.create-instance-btn');
        if (instanceBtn) {
            if (this.owner) {
                instanceBtn.classList.remove('hidden');
                instanceBtn.onclick = () => {
                    instanceBtn.disabled = true;

                    MessageBoard.show(MessageType.INFO, 'Setup started. This may take a few seconds.');

                    api(
                        XhrMethod.POST,
                        'api/create/instance',
                        undefined,
                        MessageBoard.apiCallback(MessageType.SUCCESS, 'Instance created.', () => this.refresh()),
                        MessageBoard.apiCallback(MessageType.ERROR, MessageResponse),
                        () => instanceBtn.disabled = false
                    );
                };
            }
            else {
                instanceBtn.classList.add('hidden');
            }
        }

        if (this.owner) {
            const headerRow = this.root.querySelector('.instances .header-row');
            if (headerRow) {
                headerRow.querySelector('.default-label')?.classList.add('hidden');
                headerRow.querySelector('.owner-label')?.classList.remove('hidden');
            }

            this.showOwnerStyle();
        }

        this.setUpAccessTable();
        this.setUpUserTable();
    }


    refresh() {
        api(
            XhrMethod.GET,
            '/api/dashboard',
            undefined,
            (request: XMLHttpRequest) => this.setFields(JSON.parse(request.response)),
            MessageBoard.apiCallback(MessageType.ERROR, 'Cannot show dashboard. Login first.', () => StateController.transition('login'))
        );
    }


    show() {
        this.refresh();
    }


    hide() {
        super.hide();
    }
}
