import { StateActor } from '../dom/StateController';


export class BaseActor extends StateActor {

    constructor(root: HTMLElement) {
        super(root);
    }


    show() {
    }


    hide() {
        // TODO: won't be necessary with consequent use of templates
        this.root.querySelectorAll('input').forEach(element => element.value = '');
    }
}
