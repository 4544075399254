import { StateController } from '../dom/StateController';
import { api, XhrMethod } from '../functions';
import { MessageBoard, MessageResponse, MessageType } from '../MessageBoard';
import { BaseActor } from './BaseActor';


export class RegisterActor extends BaseActor {
    constructor(root: HTMLElement) {
        super(root);

        root.querySelector('button')?.addEventListener('click', () => {
            // TODO: duplicated code
            let btn = root.querySelector('button');
            if (btn) {
                btn.disabled = true;
            }
            const email = root.querySelector<HTMLInputElement>('input[name=email]')?.value;
            const username = root.querySelector<HTMLInputElement>('input[name=username]')?.value;
            const password = root.querySelector<HTMLInputElement>('input[name=password]')?.value;
            const confirmPassword = root.querySelector<HTMLInputElement>('input[name=confirm_password]')?.value;
            const voucher = root.querySelector<HTMLInputElement>('input[name=voucher]')?.value;
            if (password !== confirmPassword) {
                MessageBoard.show(MessageType.ERROR, 'Passwords don\'t match');
                // TODO: duplicated code
                btn = root.querySelector('button');
                if (btn) {
                    btn.disabled = false;
                }
                return;
            }

            api(
                XhrMethod.POST,
                '/api/register',
                {email, username, password, voucher},
                MessageBoard.apiCallback(MessageType.SUCCESS, 'Account created! Check your mails.', () => StateController.transition('login')),
                MessageBoard.apiCallback(MessageType.ERROR, MessageResponse),
                () => {
                    // TODO: duplicated code
                    btn = root.querySelector('button');
                    if (btn) {
                        btn.disabled = false;
                    }
                }
            );
        });
    }

    show() {
        console.log('Show register');
    }

    hide() {
        super.hide();
    }
}
