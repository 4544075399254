import { Constructor } from '../functions';
import { DomTraversal, HandlerFunction } from './DomTraversal';


export const StateControllerConfig = {
    defaultState: '',
    actorCtorMap: new Map<string, Constructor<StateActor>>()
};


export class StateActor {
    constructor(readonly root: HTMLElement) {}

    show() {}
    hide() {}
}


class StateControllerSingelton {

    private readonly elementsMap = new Map<string, HTMLElement[]>();
    private readonly handlerMap = new Map<number, HandlerFunction<HTMLElement>[]>();
    private readonly actorMap = new Map<string, StateActor>();
    private readonly ATTRIBUTE_STATE = 'state';
    private readonly ATTRIBUTE_ACTOR = 'actor';

    private currentState: string;


    constructor() {
        this.handlerMap.set(DomTraversal.ELEMENT_NODE, [this.handleElement.bind(this)]);
        DomTraversal.addNodeHandlerMap(this.handlerMap);

        const searchParams = new URLSearchParams(window.location.search);
        // Parameter pollution will be handled by get because it will return the first value
        this.currentState = searchParams.get('state') ?? StateControllerConfig.defaultState;

        window.addEventListener('popstate', event => {
            const params = new URLSearchParams(window.location.search);
            // Parameter pollution will be handled by get because it will return the first value
            const state = params.get('state') ?? StateControllerConfig.defaultState;
            this.transition(state);
        });
    }


    private handleElement(element: HTMLElement): boolean {
        const attrValue = element.dataset[this.ATTRIBUTE_STATE];
        if (attrValue) {
            attrValue.split(' ').forEach(value => {
                this.elementsMap.set(value, [...(this.elementsMap.get(value) ?? []), element]);
                if (element.dataset[this.ATTRIBUTE_ACTOR] !== undefined) {
                    const ctor = StateControllerConfig.actorCtorMap.get(value);
                    if (ctor) {
                        this.actorMap.set(value, new ctor(element));
                    }
                }
            });
        }
        return true;
    }


    getState(): string {
        return this.currentState;
    }


    transition(state: string) {
        const elements = this.elementsMap.get(state);
        if (elements) {
            // keep search params when state is not changing
            let otherParams = '';
            if (this.currentState === state) {
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.delete('state');
                otherParams = '&' + [...searchParams.entries()].map(([key, value]) => key + '=' + value).join('&');
            }

            this.actorMap.get(this.currentState)?.hide();
            this.currentState = state;
            this.actorMap.get(state)?.show();
            window.history.pushState('', '', '/index.html?state=' + state + otherParams);
            // hide elements of other states
            this.elementsMap.forEach((values, key) => {
                if (key !== state) {
                    values.forEach(element => element.classList.add('hidden'));
                }
            });
            // show element of new state
            elements.forEach(element => element.classList.remove('hidden'));
        } else {
            window.console.warn('No state named "' + state + '" found! Switching to "' + StateControllerConfig.defaultState + '" by default.');
            if (StateControllerConfig.defaultState) {
                this.transition(StateControllerConfig.defaultState);
            }
        }
    }
}


export const StateController = new StateControllerSingelton();
