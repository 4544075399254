import { StateController } from '../dom/StateController';
import { api, XhrMethod } from '../functions';
import { MessageBoard, MessageResponse, MessageType } from '../MessageBoard';
import { BaseActor } from './BaseActor';


export class ResetActor extends BaseActor {
    constructor(root: HTMLElement) {
        super(root);

        root.querySelector('button')?.addEventListener('click', () => {
            const password = root.querySelector<HTMLInputElement>('input[name=password]')?.value;
            const confirmPassword = root.querySelector<HTMLInputElement>('input[name=confirm_password]')?.value;
            if (password !== confirmPassword) {
                MessageBoard.show(MessageType.ERROR, 'Passwords don\'t match');
                return;
            }

            const searchParams = new URLSearchParams(window.location.search);
            const email = searchParams.get('email');
            const code = searchParams.get('code');
            if (!email || !code) {
                StateController.transition('login');
                MessageBoard.show(MessageType.ERROR, 'Email or reset-code wasn\'t provided');
            }

            api(
                XhrMethod.POST,
                '/api/reset',
                {email, password, activation: code},
                MessageBoard.apiCallback(MessageType.SUCCESS, 'Password has been reset.', () => StateController.transition('login')),
                MessageBoard.apiCallback(MessageType.ERROR, MessageResponse)
            );
        });
    }

    show() {
        console.log('Show reset');
    }

    hide() {
        super.hide();
    }
}
