import { api, XhrMethod } from '../functions';
import { I18N, I18NKey } from './I18N';


export interface VersionInfo {
    edition: string;
    version?: string;
}


class VersionInfoControllerSingleton {

    private readonly VERSION_INFO_ATTRIBUTE     = '[version-info]';
    private readonly XYNACOM_EDITION_ATTRIBUTE  = '[xynacom-edition]';

    private _versionInfo: VersionInfo = {
        edition: I18N.translate(I18NKey.XCCE_EDITION) as string
    };


    constructor() {
    }


    private updateDOMVersionInfo() {
        document.querySelectorAll(this.XYNACOM_EDITION_ATTRIBUTE).forEach(
            element => element.textContent = this.versionInfo.edition
        );
        document.querySelectorAll(this.VERSION_INFO_ATTRIBUTE).forEach(
            element => element.textContent = this.versionInfo.version
                ? this.versionInfo.edition + ' (' + this.versionInfo.version + ')'
                : this.versionInfo.edition
        );
    }


    fetchVersionAndUpdateDom() {
        api(
            XhrMethod.GET,
            '/api/version',
            undefined,
            req => this._versionInfo = JSON.parse(req.response) as VersionInfo,
            () => this._versionInfo = {
                edition: I18N.translate(I18NKey.XCCE_EDITION) as string
            },
            () => this.updateDOMVersionInfo()
        );
    }


    get versionInfo(): VersionInfo {
        return this._versionInfo;
    }
}


export const VersionInfoController = new VersionInfoControllerSingleton();
