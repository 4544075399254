import { DashboardActor } from './actor/DashboardActor';
import { ForgetActor } from './actor/ForgetActor';
import { LoginActor } from './actor/LoginActor';
import { RegisterActor } from './actor/RegisterActor';
import { ResetActor } from './actor/ResetActor';
import { DomTraversal } from './dom/DomTraversal';
import { I18N, I18NKey } from './dom/I18N';
import { ModalController } from './dom/ModalController';
import { StateController, StateControllerConfig } from './dom/StateController';
import { Templates } from './dom/Templates';
import { VersionInfoController } from './dom/VersionInfoController';
import { getEnumKeys } from './functions';
import { MessageBoard, MessageType } from './MessageBoard';


/**
 * On page load get the message from the search params and load it
 */
const msg = new URLSearchParams(window.location.search).get('msg') ?? '';
getEnumKeys(MessageType).forEach(type => {
    const typePrefix = MessageType[type];
    if (msg.startsWith(typePrefix)) {
        MessageBoard.show(type, msg.replace(typePrefix, ''));
    }
});

VersionInfoController.fetchVersionAndUpdateDom();

StateControllerConfig.defaultState = 'login';
StateControllerConfig.actorCtorMap.set('dashboard', DashboardActor);
StateControllerConfig.actorCtorMap.set('forget', ForgetActor);
StateControllerConfig.actorCtorMap.set('login', LoginActor);
StateControllerConfig.actorCtorMap.set('register', RegisterActor);
StateControllerConfig.actorCtorMap.set('reset', ResetActor);
DomTraversal.traverseNode(window.document.body);
StateController.transition(StateController.getState());


/**
 * Listen to pressing the enter key to submit data in forms
 */
[...document.getElementsByClassName('form')].forEach(form => {
    const btn = form.querySelector<HTMLButtonElement>('.submit-btn');
    if (btn) {
        [...form.getElementsByTagName('input')].forEach(input => {
            input.onkeydown = event => {
                if (event.key === 'Enter') {
                    btn.click();
                }
            };
        });
    }
});

[...document.getElementsByClassName('state-changer')].forEach(element => {
    const targetState = element.getAttribute('target-state');
    if (targetState) {
        element.addEventListener('click', () => {
            StateController.transition(targetState);
        });
    }
});

document.querySelector('.impressum-clickable')?.addEventListener('click', () => {
    ModalController.dialog(
        I18N.translate(I18NKey.DIALOG_TITLE_INFO),
        Templates.getClone('impressum'),
        ModalController.closeAction(I18N.translate(I18NKey.DIALOG_ACTION_OK))
    );
});
