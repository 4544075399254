import { DomTraversal, HandlerFunction } from './DomTraversal';


class TemplatesSingelton {

    private readonly elementMap = new Map<string, HTMLElement>();
    private readonly handlerMap = new Map<number, HandlerFunction<HTMLElement>[]>();
    private readonly ATTRIBUTE = 'template';


    constructor() {
        // add handler map to dom traversal
        this.handlerMap.set(DomTraversal.ELEMENT_NODE, [this.handleElement.bind(this)]);
        DomTraversal.addNodeHandlerMap(this.handlerMap);
    }


    private handleElement(element: HTMLElement): boolean {
        const attr = element.dataset[this.ATTRIBUTE];
        if (attr) {
            // store element in map
            this.elementMap.set(attr, element);
            // remove attribute from element
            delete element.dataset[this.ATTRIBUTE];
            // remove element from its parent
            element.parentElement?.removeChild(element);
            // traverse element exclusively for this handler map
            DomTraversal.traverseNode(element, this.handlerMap);
            return false;
        }
        return true;
    }


    getClone(name: string): HTMLElement | undefined {
        const element = this.elementMap.get(name);
        if (element) {
            return DomTraversal.traverseNode(element.cloneNode(true) as HTMLElement);
        }
        window.console.warn('No template named "' + name + '" found!');
    }
}


export const Templates = new TemplatesSingelton();
