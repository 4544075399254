export class DataForm {

    readonly element: HTMLElement;
    readonly items = new Array<HTMLElement>();


    constructor(root: HTMLElement, name?: string) {
        if (name) {
            const form = root.querySelector('[data-form="' + name + '"]') as HTMLElement;
            if (!form) {
                console.warn('DataForm: invalid element "(' + name + ')"');
            }
            this.element = form ?? new HTMLElement();
        } else {
            this.element = root;
        }
        this.addItems(this.element);
    }


    addItems(element: HTMLElement) {
        this.items.push(...Array.prototype.slice.call(element.querySelectorAll('[data-item]')));
    }


    getItem<T extends HTMLElement = HTMLElement>(name: string): T | undefined {
        return this.items.filter(item => item.dataset.item === name)[0] as T;
    }
}
