import { isString } from '../functions';
import { DomTraversal } from './DomTraversal';


export enum I18NLang {
    DE_DE,
    EN_US
}


export enum I18NKey {
    XCCE_EDITION,
    // menu
    MENU_REGISTER,
    MENU_LOGIN,
    MENU_LOGOUT,
    // dashboard
    FORM_DASHBOARD_TITLE,
    FORM_DASHBOARD_GREETING,
    FORM_DASHBOARD_ACTION_VOUCHER,
    FORM_DASHBOARD_ACTION_LOGOUT_FACTORY,
    FORM_DASHBOARD_ACTION_DELETE,
    INSTANCE_NAME,
    INSTANCE_ROLE,
    INSTANCE_STATUS,
    INSTANCE_FACTORY,
    VOUCHER_TABLE,
    VOUCHER_VALUE,
    VOUCHER_ROLE,
    VOUCHER_AVAIL,
    VOUCHER_DEFAULT_ACCESS_NAME,
    VOUCHER_TRIM_INFO,
    VOUCHER_ACTIONS,
    EDIT_VOUCHER,
    DELETE_VOUCHER,
    SUBMIT_VOUCHER,
    REDEEM_VOUCHER,
    USER_ACCESS_TABLE,
    ACCESS_USER,
    ACCESS_NAME,
    ACCESS_ENDPOINTS,
    ACCESS_ROLE,
    ACCESS_ACTIONS,
    REMOVE_ACCESS,
    ADD_ACCESS,
    ADD_VOUCHER,
    UPDATE_ACCESS,
    UPDATE_VOUCHER,
    SHOW_DETAILS,
    CREATE_API_KEY,
    CREATE_KEY_TITLE,
    CONFIRM_API_KEY,
    GENERATE_SSH_COMMANDS,
    DELETE_FACTORY,
    REMOVE_FACTORY_ACCESS,
    OPEN_FACTORY,
    OPEN,
    SSH_COMMANDS,
    LINUX_COMMAND,
    WINDOWS_COMMAND,
    USER_NAME,
    USER_ROLE,
    USER_EMAIL,
    USER_ACTIONS,
    LOCK_USER,
    UNLOCK_USER,
    DELETE_USER,
    // register
    FORM_REGISTER_TITLE,
    FORM_REGISTER_INPUT_EMAIL,
    FORM_REGISTER_INPUT_USERNAME,
    FORM_REGISTER_INPUT_PASSWORD,
    FORM_REGISTER_INPUT_PASSWORD_CONFIRM,
    FORM_REGISTER_INPUT_VOUCHER,
    FORM_REGISTER_ACTION_REGISTER,
    // login
    FORM_LOGIN_TITLE,
    FORM_LOGIN_INPUT_USERNAME,
    FORM_LOGIN_INPUT_PASSWORD,
    FORM_LOGIN_ACTION_FORGOT_PASSWORD,
    FORM_LOGIN_ACTION_LOGIN,
    // forgot
    FORM_FORGOT_TITLE,
    FORM_FORGOT_INPUT_EMAIL,
    FORM_FORGOT_INFO,
    FORM_FORGOT_ACTION_FORGOT,
    // reset
    FORM_RESET_TITLE,
    FORM_RESET_INPUT_NEW_PASSWORD,
    FORM_RESET_INPUT_NEW_PASSWORD_CONFIRM,
    FORM_RESET_ACTION_RESET,
    // message board
    MESSAGE_ERROR,
    MESSAGE_INFO,
    MESSAGE_SUCCESS,
    MESSAGE_SYSTEM,
    MAINTENANCE_MSG,
    // dialogs
    DIALOG_TITLE_ERROR,
    DIALOG_TITLE_INFO,
    DIALOG_TITLE_CONFIRM,
    DIALOG_ACTION_CANCEL,
    DIALOG_ACTION_ADD,
    DIALOG_ACTION_REMOVE,
    DIALOG_ACTION_RENAME,
    DIALOG_ACTION_BACK,
    DIALOG_ACTION_OK,
    DIALOG_ACTION_YES,
    DIALOG_ACTION_NO,
    DIALOG_ACTION_COPY
}


class I18NSingelton {

    private readonly i18n = new Map<I18NLang, Map<I18NKey, string>>([
        [I18NLang.EN_US, new Map([
            [I18NKey.XCCE_EDITION, 'Community Edition'],
            [I18NKey.MENU_REGISTER, 'Register'],
            [I18NKey.MENU_LOGIN, 'Login'],
            [I18NKey.MENU_LOGOUT, 'Logout'],
            [I18NKey.FORM_DASHBOARD_TITLE, 'Dashboard'],
            [I18NKey.FORM_DASHBOARD_GREETING, 'Welcome'],
            [I18NKey.FORM_DASHBOARD_ACTION_VOUCHER, 'Create Voucher'],
            [I18NKey.FORM_DASHBOARD_ACTION_LOGOUT_FACTORY, 'Logout from Factory'],
            [I18NKey.FORM_DASHBOARD_ACTION_DELETE, 'Delete Account'],
            [I18NKey.INSTANCE_NAME, 'Name'],
            [I18NKey.INSTANCE_ROLE, 'Role'],
            [I18NKey.INSTANCE_STATUS, 'Status'],
            [I18NKey.INSTANCE_FACTORY, 'Factory'],
            [I18NKey.VOUCHER_TABLE, 'Voucher'],
            [I18NKey.VOUCHER_VALUE, 'Value'],
            [I18NKey.VOUCHER_ROLE, 'Role'],
            [I18NKey.VOUCHER_AVAIL, 'Avail'],
            [I18NKey.VOUCHER_DEFAULT_ACCESS_NAME, 'Default Access Name'],
            [I18NKey.VOUCHER_TRIM_INFO, 'Surrounding whitespaces will be removed.'],
            [I18NKey.VOUCHER_ACTIONS, 'Actions'],
            [I18NKey.EDIT_VOUCHER, 'Edit Voucher'],
            [I18NKey.DELETE_VOUCHER, 'Delete Voucher'],
            [I18NKey.SUBMIT_VOUCHER, 'Submit Voucher'],
            [I18NKey.REDEEM_VOUCHER, 'Redeem voucher to add access'],
            [I18NKey.USER_ACCESS_TABLE, 'User-Access'],
            [I18NKey.ACCESS_USER, 'User'],
            [I18NKey.ACCESS_NAME, 'Access name'],
            [I18NKey.ACCESS_ENDPOINTS, 'Endpoints'],
            [I18NKey.ACCESS_ROLE, 'Access role'],
            [I18NKey.ACCESS_ACTIONS, 'Actions'],
            [I18NKey.REMOVE_ACCESS, 'Remove Access'],
            [I18NKey.ADD_ACCESS, 'Add Access To Factory'],
            [I18NKey.ADD_VOUCHER, 'Create Voucher For Factory'],
            [I18NKey.UPDATE_ACCESS, 'Update Access'],
            [I18NKey.UPDATE_VOUCHER, 'Update Voucher'],
            [I18NKey.SHOW_DETAILS, 'Show Details'],
            [I18NKey.CREATE_API_KEY, 'API'],
            [I18NKey.CREATE_KEY_TITLE, 'Create API-Key'],
            [I18NKey.CONFIRM_API_KEY, 'Creating a new API-Key will overwrite the current API-Key. Do you want to proceed? Current API-Key:'],
            [I18NKey.GENERATE_SSH_COMMANDS, 'Generate SSH commands'],
            [I18NKey.DELETE_FACTORY, 'Delete Factory'],
            [I18NKey.REMOVE_FACTORY_ACCESS, 'Remove Access to Factory'],
            [I18NKey.OPEN_FACTORY, 'Open Factory'],
            [I18NKey.OPEN, 'Open'],
            [I18NKey.SSH_COMMANDS, 'SSH-Commands'],
            [I18NKey.LINUX_COMMAND, 'Linux-Command'],
            [I18NKey.WINDOWS_COMMAND, 'Windows-Command'],
            [I18NKey.USER_NAME, 'Username'],
            [I18NKey.USER_ROLE, 'Role'],
            [I18NKey.USER_EMAIL, 'E-Mail'],
            [I18NKey.USER_ACTIONS, 'Actions'],
            [I18NKey.LOCK_USER, 'Lock User'],
            [I18NKey.UNLOCK_USER, 'Unlock User'],
            [I18NKey.DELETE_USER, 'Delete User'],
            [I18NKey.FORM_REGISTER_TITLE, 'Register a new account'],
            [I18NKey.FORM_REGISTER_INPUT_EMAIL, 'Email'],
            [I18NKey.FORM_REGISTER_INPUT_USERNAME, 'Username'],
            [I18NKey.FORM_REGISTER_INPUT_PASSWORD, 'Password'],
            [I18NKey.FORM_REGISTER_INPUT_PASSWORD_CONFIRM, 'Confirm Password'],
            [I18NKey.FORM_REGISTER_INPUT_VOUCHER, 'Voucher'],
            [I18NKey.FORM_REGISTER_ACTION_REGISTER, 'Register'],
            [I18NKey.FORM_LOGIN_TITLE, 'Login'],
            [I18NKey.FORM_LOGIN_INPUT_USERNAME, 'Username'],
            [I18NKey.FORM_LOGIN_INPUT_PASSWORD, 'Password'],
            [I18NKey.FORM_LOGIN_ACTION_FORGOT_PASSWORD, 'Forgot Password'],
            [I18NKey.FORM_LOGIN_ACTION_LOGIN, 'Login'],
            [I18NKey.FORM_FORGOT_TITLE, 'Forgot Password'],
            [I18NKey.FORM_FORGOT_INPUT_EMAIL, 'Email'],
            [I18NKey.FORM_FORGOT_INFO, 'We will send an email with an activation code to this address, so you can reset your password.'],
            [I18NKey.FORM_FORGOT_ACTION_FORGOT, 'Send email'],
            [I18NKey.FORM_RESET_TITLE, 'Reset Password'],
            [I18NKey.FORM_RESET_INPUT_NEW_PASSWORD, 'New password'],
            [I18NKey.FORM_RESET_INPUT_NEW_PASSWORD_CONFIRM, 'Confirm new password'],
            [I18NKey.FORM_RESET_ACTION_RESET, 'Reset'],
            [I18NKey.MESSAGE_ERROR, 'Error'],
            [I18NKey.MESSAGE_INFO, 'Info'],
            [I18NKey.MESSAGE_SUCCESS, 'Success'],
            [I18NKey.MESSAGE_SYSTEM, 'System'],
            [I18NKey.MAINTENANCE_MSG, 'Due to an update the Xyna.Com Community Edition Service won\'t be available on the following day:'],
            [I18NKey.DIALOG_TITLE_ERROR, 'Error'],
            [I18NKey.DIALOG_TITLE_INFO, 'Info'],
            [I18NKey.DIALOG_TITLE_CONFIRM, 'Confirm'],
            [I18NKey.DIALOG_ACTION_CANCEL, 'Cancel'],
            [I18NKey.DIALOG_ACTION_ADD, 'Add'],
            [I18NKey.DIALOG_ACTION_REMOVE, 'Remove'],
            [I18NKey.DIALOG_ACTION_RENAME, 'Rename'],
            [I18NKey.DIALOG_ACTION_BACK, 'Back'],
            [I18NKey.DIALOG_ACTION_OK, 'Ok'],
            [I18NKey.DIALOG_ACTION_YES, 'Yes'],
            [I18NKey.DIALOG_ACTION_NO, 'No'],
            [I18NKey.DIALOG_ACTION_COPY, 'Copy']
        ])],
        [I18NLang.DE_DE, new Map([
            [I18NKey.XCCE_EDITION, 'Community Edition'],
            [I18NKey.MENU_REGISTER, 'Registrieren'],
            [I18NKey.MENU_LOGIN, 'Anmelden'],
            [I18NKey.MENU_LOGOUT, 'Abmelden'],
            [I18NKey.FORM_DASHBOARD_TITLE, 'Dashboard'],
            [I18NKey.FORM_DASHBOARD_GREETING, 'Willkommen'],
            [I18NKey.FORM_DASHBOARD_ACTION_VOUCHER, 'Einladungscode erstellen'],
            [I18NKey.FORM_DASHBOARD_ACTION_LOGOUT_FACTORY, 'Factory ausloggen'],
            [I18NKey.FORM_DASHBOARD_ACTION_DELETE, 'Konto löschen'],
            [I18NKey.INSTANCE_NAME, 'Name'],
            [I18NKey.INSTANCE_ROLE, 'Rolle'],
            [I18NKey.INSTANCE_STATUS, 'Status'],
            [I18NKey.INSTANCE_FACTORY, 'Factory'],
            [I18NKey.VOUCHER_TABLE, 'Voucher'],
            [I18NKey.VOUCHER_VALUE, 'Name'],
            [I18NKey.VOUCHER_ROLE, 'Rolle'],
            [I18NKey.VOUCHER_AVAIL, 'Verwendungen'],
            [I18NKey.VOUCHER_DEFAULT_ACCESS_NAME, 'Initialer Zugriffsname'],
            [I18NKey.VOUCHER_TRIM_INFO, 'Führende und folgende Leerzeichen werden entfernt.'],
            [I18NKey.VOUCHER_ACTIONS, 'Aktionen'],
            [I18NKey.EDIT_VOUCHER, 'Voucher bearbeiten'],
            [I18NKey.DELETE_VOUCHER, 'Voucher löschen'],
            [I18NKey.SUBMIT_VOUCHER, 'Voucher senden'],
            [I18NKey.REDEEM_VOUCHER, 'Voucher einlösen, um Zugang zu erhalten'],
            [I18NKey.USER_ACCESS_TABLE, 'Nutzerzugriffe'],
            [I18NKey.ACCESS_USER, 'Nutzer'],
            [I18NKey.ACCESS_NAME, 'Zugriffsname'],
            [I18NKey.ACCESS_ENDPOINTS, 'Endpoints'],
            [I18NKey.ACCESS_ROLE, 'Rolle'],
            [I18NKey.ACCESS_ACTIONS, 'Aktionen'],
            [I18NKey.REMOVE_ACCESS, 'Zugriff entfernen'],
            [I18NKey.ADD_ACCESS, 'Zugriff zu Factory hinzufügen'],
            [I18NKey.ADD_VOUCHER, 'Voucher hinzufügen für Factory'],
            [I18NKey.UPDATE_ACCESS, 'Zugriff updaten'],
            [I18NKey.UPDATE_VOUCHER, 'Bearbeite Voucher'],
            [I18NKey.SHOW_DETAILS, 'Details anzeigen'],
            [I18NKey.CREATE_API_KEY, 'API'],
            [I18NKey.CREATE_KEY_TITLE, 'API-Key erzeugen'],
            [I18NKey.CONFIRM_API_KEY, 'Durch das Erstellen eines neuen API-Keys, wird der bisherige API-Key überschrieben. Wirklich fortfahren? Aktueller API-Key:'],
            [I18NKey.GENERATE_SSH_COMMANDS, 'Erzeuge SSH Befehle'],
            [I18NKey.DELETE_FACTORY, 'Factory löschen'],
            [I18NKey.REMOVE_FACTORY_ACCESS, 'Zugriff auf Factory entfernen'],
            [I18NKey.OPEN_FACTORY, 'Factory öffnen'],
            [I18NKey.OPEN, 'Öffnen'],
            [I18NKey.SSH_COMMANDS, 'SSH-Befehle'],
            [I18NKey.LINUX_COMMAND, 'Linux-Befehl'],
            [I18NKey.WINDOWS_COMMAND, 'Windows-Befehl'],
            [I18NKey.USER_NAME, 'Benutzername'],
            [I18NKey.USER_ROLE, 'Rolle'],
            [I18NKey.USER_EMAIL, 'E-Mail'],
            [I18NKey.USER_ACTIONS, 'Aktionen'],
            [I18NKey.LOCK_USER, 'Benutzer sperren'],
            [I18NKey.UNLOCK_USER, 'Benutzer entsperren'],
            [I18NKey.DELETE_USER, 'Benutzer löschen'],
            [I18NKey.FORM_REGISTER_TITLE, 'Neues Konto registrieren'],
            [I18NKey.FORM_REGISTER_INPUT_EMAIL, 'Email'],
            [I18NKey.FORM_REGISTER_INPUT_USERNAME, 'Benutzername'],
            [I18NKey.FORM_REGISTER_INPUT_PASSWORD, 'Kennwort'],
            [I18NKey.FORM_REGISTER_INPUT_PASSWORD_CONFIRM, 'Kennwort bestätigen'],
            [I18NKey.FORM_REGISTER_INPUT_VOUCHER, 'Einladungscode'],
            [I18NKey.FORM_REGISTER_ACTION_REGISTER, 'Registrieren'],
            [I18NKey.FORM_LOGIN_TITLE, 'Anmelden'],
            [I18NKey.FORM_LOGIN_INPUT_USERNAME, 'Benutzername'],
            [I18NKey.FORM_LOGIN_INPUT_PASSWORD, 'Kennwort'],
            [I18NKey.FORM_LOGIN_ACTION_FORGOT_PASSWORD, 'Kennwort vergessen'],
            [I18NKey.FORM_LOGIN_ACTION_LOGIN, 'Anmelden'],
            [I18NKey.FORM_FORGOT_TITLE, 'Kennwort vergessen'],
            [I18NKey.FORM_FORGOT_INPUT_EMAIL, 'Email'],
            [I18NKey.FORM_FORGOT_INFO, 'Es wird eine Email mit einem Aktivierungslink an diese Adresse gesendet, um das Kennwort zurückzusetzen.'],
            [I18NKey.FORM_FORGOT_ACTION_FORGOT, 'Email senden'],
            [I18NKey.FORM_RESET_TITLE, 'Kennwort zurücksetzen'],
            [I18NKey.FORM_RESET_INPUT_NEW_PASSWORD, 'Neues Kennwort'],
            [I18NKey.FORM_RESET_INPUT_NEW_PASSWORD_CONFIRM, 'Neues Kennwort bestätigen'],
            [I18NKey.FORM_RESET_ACTION_RESET, 'Zurücksetzen'],
            [I18NKey.MESSAGE_ERROR, 'Fehler'],
            [I18NKey.MESSAGE_INFO, 'Info'],
            [I18NKey.MESSAGE_SUCCESS, 'Erfolg'],
            [I18NKey.MESSAGE_SYSTEM, 'System'],
            [I18NKey.MAINTENANCE_MSG, 'Aufgrund eines Updates wird der Xyna.Com Community Edition Service am folgenden Tag nicht erreichbar sein: '],
            [I18NKey.DIALOG_TITLE_ERROR, 'Fehler'],
            [I18NKey.DIALOG_TITLE_INFO, 'Info'],
            [I18NKey.DIALOG_TITLE_CONFIRM, 'Bestätigen'],
            [I18NKey.DIALOG_ACTION_CANCEL, 'Abbrechen'],
            [I18NKey.DIALOG_ACTION_ADD, 'Hinzufügen'],
            [I18NKey.DIALOG_ACTION_REMOVE, 'Entfernen'],
            [I18NKey.DIALOG_ACTION_RENAME, 'Umbenennen'],
            [I18NKey.DIALOG_ACTION_BACK, 'Zurück'],
            [I18NKey.DIALOG_ACTION_OK, 'Ok'],
            [I18NKey.DIALOG_ACTION_YES, 'Ja'],
            [I18NKey.DIALOG_ACTION_NO, 'Nein'],
            [I18NKey.DIALOG_ACTION_COPY, 'Kopiere']
        ])]
    ]);

    private readonly cache = new Map<I18NKey, string>();

    private readonly PREFIX = 'I18N.';
    private readonly ATTRIBUTE_NAMES = ['placeholder', 'title'];


    constructor() {
        // define localization
        const url = new window.URL(window.location.toString());
        const lang = url.searchParams.get('lang') ?? '';
        this.define(I18NLang[lang as keyof typeof I18NLang]);
        // add handlers to dom traversal
        DomTraversal.addNodeHandler(this.handleElement.bind(this), DomTraversal.ELEMENT_NODE);
        DomTraversal.addNodeHandler(this.handleText.bind(this), DomTraversal.TEXT_NODE);
    }


    private handleElement(node: HTMLElement): boolean {
        this.ATTRIBUTE_NAMES.forEach(attributeName => {
            const attributeValue = node.getAttribute(attributeName);
            if (attributeValue && attributeValue.startsWith(this.PREFIX)) {
                let translation;
                if ((translation = this.translatePrefixed(attributeValue)) != null) {
                    node.setAttribute(attributeName, translation);
                }
            }
        });
        return true;
    }


    private handleText(node: HTMLElement): boolean {
        let translation;
        if ((translation = this.translatePrefixed(node.nodeValue ?? this.PREFIX)) != null) {
            node.nodeValue = translation;
        }
        return true;
    }


    private translatePrefixed(key: string): string | undefined {
        return this.translateRaw(key.substring(this.PREFIX.length));
    }


    private translateRaw(key: string): string | undefined {
        return this.translate(key as keyof typeof I18NKey);
    }


    translate(key: keyof typeof I18NKey | I18NKey): string | undefined {
        if (!isString(key)) {
            key = I18NKey[key] as keyof typeof I18NKey;
        }
        return this.cache.get(I18NKey[key]);
    }


    define(lang: I18NLang) {
        this.cache.clear();
        const dictionary = this.i18n.get(lang) ?? this.i18n.get(I18NLang.EN_US) ?? new Map();
        dictionary.forEach((_, key) => {
            this.cache.set(key, dictionary.get(key) ?? I18NKey[key]);
        });
    }
}


export const I18N = new I18NSingelton();
