import { StateController } from '../dom/StateController';
import { api, XhrMethod } from '../functions';
import { MessageBoard, MessageResponse, MessageType } from '../MessageBoard';
import { BaseActor } from './BaseActor';


export class ForgetActor extends BaseActor {
    constructor(root: HTMLElement) {
        super(root);

        root.querySelector('button')?.addEventListener('click', () => {
            const email = root.querySelector<HTMLInputElement>('input[name=email]')?.value;
            api(
                XhrMethod.POST,
                '/api/forget',
                {email},
                MessageBoard.apiCallback(MessageType.INFO, MessageResponse, () => StateController.transition('login')),
                MessageBoard.apiCallback(MessageType.INFO, MessageResponse)
            );
        });
    }

    show() {
        console.log('Show forget');
    }

    hide() {
        super.hide();
    }
}
