export type HandlerFunction<T extends Node> = (node: T) => boolean;
export type HandlerMap<T extends Node> = Map<number, HandlerFunction<T>[]>;


class DomTraversalSingelton<T extends Node> {

    private readonly nodeHandlerMap = new Map<number, HandlerFunction<T>[]>();


    private handleNode(node: T, exclusiveHandlerMap?: HandlerMap<T>) {
        const handlerMap = exclusiveHandlerMap ?? this.nodeHandlerMap;
        let traverseChildren = true;
        (handlerMap.get(node.nodeType) ?? []).forEach(handler => {
            traverseChildren &&= handler(node);
        });
        return traverseChildren;
    }


    readonly ELEMENT_NODE                = window.Node.ELEMENT_NODE;
    readonly TEXT_NODE                   = window.Node.TEXT_NODE;
    readonly PROCESSING_INSTRUCTION_NODE = window.Node.PROCESSING_INSTRUCTION_NODE;
    readonly COMMENT_NODE                = window.Node.COMMENT_NODE;
    readonly DOCUMENT_NODE               = window.Node.DOCUMENT_NODE;
    readonly DOCUMENT_TYPE_NODE          = window.Node.DOCUMENT_TYPE_NODE;
    readonly DOCUMENT_FRAGMENT_NODE      = window.Node.DOCUMENT_FRAGMENT_NODE;


    addNodeHandler(handler: HandlerFunction<T>, type: number) {
        this.nodeHandlerMap.set(type, [...(this.nodeHandlerMap.get(type) ?? []), handler]);
    }


    addNodeHandlerMap(handlerMap: HandlerMap<T>) {
        handlerMap.forEach((handlers, type) => handlers.forEach(handler => this.addNodeHandler(handler, type)));
    }


    removeNodeHandler(handler: HandlerFunction<T>, type: number) {
        const handlerMap = this.nodeHandlerMap.get(type);
        if (handlerMap) {
            const idx = handlerMap.indexOf(handler);
            if (idx >= 0) {
                handlerMap.splice(idx, 1);
            }
        }
    }


    removeNodeHandlerMap(handlerMap: HandlerMap<T>) {
        handlerMap.forEach((handlers, type) => handlers.forEach(handler => this.removeNodeHandler(handler, type)));
    }


    traverseNode(node: T, exclusiveHandlerMap?: HandlerMap<T>): T {
        if (this.handleNode(node, exclusiveHandlerMap) && node.childNodes) {
            const childNodes = Array.prototype.slice.call(node.childNodes);
            childNodes.forEach(childNode => {
                this.traverseNode(childNode, exclusiveHandlerMap);
            });
        }
        return node;
    }
}


export const DomTraversal = new DomTraversalSingelton<HTMLElement>();
